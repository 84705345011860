export const PLAYER_DEFAULT_STATION_ID: StationId = "mix1";
export const PLAYER_DEFAULT_BITRATE_FREE: Bitrate = "audio-mp4-aac-128k";
export const PLAYER_DEFAULT_BITRATE_PREMIUM: Bitrate = "audio-mp4-aac-128k";
export const PLAYER_DEFAULT_STREAM_FORMAT: StreamFormat = "pls";

export const BITRATE_OPTIONS: ReadonlyArray<JGOption<Bitrate>> = [
  { label: "128k AAC (Good)", value: "audio-mp4-aac-128k" },
  { label: "256k AAC (Great)", value: "audio-mp4-aac-256k" },
  { label: "FLAC (Best)", value: "audio-mp4-flac" },
];

export const STREAM_FORMAT_OPTIONS: ReadonlyArray<JGOption<StreamFormat>> = [
  { label: "PLS", value: "pls" },
  { label: "Stream URL", value: "stream" },
];

export const EXPANDED_STREAM_PATHS: Readonly<
  Record<StationId, Record<Bitrate, string>>
> = Object.freeze({
  mix1: Object.freeze({
    "audio-mp4-aac-64k": "345aae79-f44c-44fd-b122-8401ccf57872",
    "audio-mp4-aac-128k": "6441db21-9a8e-4745-af4d-ab0251c5312e",
    "audio-mp4-aac-256k": "0fc134ba-991c-4b1f-9b06-7e0358f1d680",
    "audio-mp4-flac": "d0105650-c51c-4842-ae39-f97b329acd2a",
  }),
  mix2: Object.freeze({
    "audio-mp4-aac-64k": "edbf84c2-5e3a-476e-ab3d-7ee5953ef6e7",
    "audio-mp4-aac-128k": "05de889c-5678-4a40-a36d-177e9ead2aa9",
    "audio-mp4-aac-256k": "aee9d7d1-9cf1-4db6-bb1c-808f95a17283",
    "audio-mp4-flac": "4482dfb3-d502-42ef-8184-fc56b0f3ed44",
  }),
  dreams: Object.freeze({
    "audio-mp4-aac-64k": "6d5c3073-a65f-44df-8cb7-c84a376cf991",
    "audio-mp4-aac-128k": "6cced571-fcb1-4410-84af-1f93aa434809",
    "audio-mp4-aac-256k": "ede77312-b4b9-4d27-88a3-47b0898f845b",
    "audio-mp4-flac": "1769696b-ae90-417f-b007-584c1b6edef8",
  }),
  gems: Object.freeze({
    "audio-mp4-aac-64k": "42365472-8c1d-4610-9a92-1ef14a71d53b",
    "audio-mp4-aac-128k": "ea3dafa6-0e4a-4d80-ae2a-2a2b40e51fd7",
    "audio-mp4-aac-256k": "4b3f623d-78a8-4741-a723-a4a11ea25083",
    "audio-mp4-flac": "e2f4e41b-e801-45d1-8241-a609b470187e",
  }),
  smooth: Object.freeze({
    "audio-mp4-aac-64k": "0c7b380f-c009-4bb8-8235-ce8663719de4",
    "audio-mp4-aac-128k": "629959c2-59c4-4a58-9d05-3df5e4da6c5f",
    "audio-mp4-aac-256k": "7ed7b90c-3668-4b40-a615-a00eb0fc78b0",
    "audio-mp4-flac": "1614c7bc-e84e-4f64-8d22-1226d17cd681",
  }),
  christmas: Object.freeze({
    "audio-mp4-aac-64k": "351bc659-1163-4487-9299-a7d5fb2477ed",
    "audio-mp4-aac-128k": "e156218c-3d28-4a1c-a262-f0c1ece0baee",
    "audio-mp4-aac-256k": "dc897e41-4f53-4f52-a0a3-05a23c580647",
    "audio-mp4-flac": "71cb1023-3c47-4c06-847d-5b15a3ddefee",
  }),
  demo: Object.freeze({
    "audio-mp4-aac-64k": "345aae79-f44c-44fd-b122-8401ccf57872",
    "audio-mp4-aac-128k": "6441db21-9a8e-4745-af4d-ab0251c5312e",
    "audio-mp4-aac-256k": "0fc134ba-991c-4b1f-9b06-7e0358f1d680",
    "audio-mp4-flac": "d0105650-c51c-4842-ae39-f97b329acd2a",
  }),
});
