import React from "react";
import { Redirect, Route } from "react-router";
import queryString from "query-string";

/**
 * Regex of pathnames not to add the channel parameter to.
 */
const excludedPathnameRegexes = [
  "/checkout(/*)?",
  "/login",
  "/signup",
  "/sign-up",
];

const concatenatedRegexes = excludedPathnameRegexes.map(re => `(${re})`).join("|");
const excludedPathnamesRe = new RegExp(`^${concatenatedRegexes}$`);

const ChannelRedirect = (props) => (
    <Route
      render={(routeProps) => {
        if (excludedPathnamesRe.test(routeProps.location.pathname)) {
          return null;
        }

        let values = queryString.parse(routeProps.location.search);
        if (values.channel && values.channel === props.channel) {
          return null;
        } else {
          values.channel = props.channel;
          return (
            <Redirect
              to={{
                pathname: routeProps.location.pathname,
                hash: routeProps.location.hash,
                search: queryString.stringify(values),
                state: routeProps.location.state,
              }}
            />
          );
        }
      }}
    />
  );

  export default ChannelRedirect;
